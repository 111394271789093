'use client';

import { getAnalytics, isSupported, logEvent as logFirebaseEvent } from 'firebase/analytics';
import type { EventTypes } from './EventType';

// Ignore sending product analytics events in development and test environments
const IS_DEV_ENV = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

// FIXME: This function should be renamed to `logFirebaseEvent` or be agnostic to the analytics services we use
export async function logScreenEvent<Key extends keyof EventTypes>(
  eventName: Key,
  eventParams?: EventTypes[Key] extends never ? undefined : EventTypes[Key]
): Promise<void> {
  if (IS_DEV_ENV) {
    // eslint-disable-next-line no-console -- Allow console.log for development
    console.debug('[Firebase Analytics] logScreenEvent', eventName, eventParams);
    return;
  }
  try {
    const supported = await isSupported();
    if (!supported) {
      return;
    }
    const analytics = getAnalytics();
    logFirebaseEvent(analytics, eventName as 'anything', eventParams); // hacky typing to allow any string as event name - issue with using 'login' as event name
  } catch (error) {
    // no-op
  }
}

export async function logEvent<Key extends keyof EventTypes>(
  eventName: Key,
  eventParams?: EventTypes[Key] extends never ? undefined : EventTypes[Key]
): Promise<void> {
  // eslint-disable-next-line no-console -- Allow console.log for development
  console.debug('[Firebase Analytics] logEvent', eventName, eventParams);
  await logScreenEvent(eventName, eventParams);
}
