'use client';

import { type ReactNode, useEffect, useRef } from 'react';
import { useAppSelector } from '../../store';
import { logAdobeEvent } from '../AdobeAnalytics/adobeAnalytics';
import { logScreenEvent } from '../FirebaseAnalytics/firebaseAnalytics';
import type { AnalyticsContextValue } from '../AnalyticsContext';
import { useAnalyticsContext } from '../AnalyticsContext';

export interface BaseTrackScreenViewWrapperProps {
  eventName: string;
  pageContext: AnalyticsContextValue;
  pageName: string;
  children: ReactNode;
}

export function BaseTrackScreenViewWrapper({
  eventName,
  pageContext,
  pageName,
  children,
}: BaseTrackScreenViewWrapperProps) {
  const pageLoadedRef = useRef(false);
  const { adobeCloudID = '' } = useAppSelector((state) => state.adobeSlice);

  useEffect(() => {
    // Prevents multiple reloads (e.g. hot reloading)
    if (pageLoadedRef.current) {
      return;
    }
    pageLoadedRef.current = true;
    // Firebase
    void logScreenEvent('page_view', { screen_name: pageName, context: pageContext });
    // Adobe
    const event = `${process.env.ADOBE_PLATFORM}:${pageContext}:${pageName}`.toLocaleLowerCase();
    logAdobeEvent(eventName, event, pageContext, pageName, adobeCloudID);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- No dependencies needed because it will only be run one time
  }, []);

  return children;
}

export function TrackScreenViewWrapper({
  eventName,
  pageName,
  children,
}: Omit<BaseTrackScreenViewWrapperProps, 'pageContext'>) {
  const pageContext = useAnalyticsContext();

  return (
    <BaseTrackScreenViewWrapper eventName={eventName} pageContext={pageContext} pageName={pageName}>
      {children}
    </BaseTrackScreenViewWrapper>
  );
}
